import React from 'react'
import { CompanyName } from './Component/CompanyName'
import Logo from './Images/Logo.png'
import { Button } from '@mui/material'
import { createTheme, ThemeProvider } from "@mui/material/styles";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { motion } from "framer-motion";

const theme = createTheme({
    palette: {
        primary: {
            main: "#3D6574", // custom primary color
        },
        secondary: {
            main: "#EBE2D3", // custom secondary color
        },
    },
});

export default function About() {

    const motionVariants = {
        initial: { x: "100%" }, // Start position (right side)
        animate: { x: "0%" },   // End position (left side)
    };

    const motionVariant2 = {
        initial: { x: "-50%" }, // Start position (right side)
        animate: { x: "0%" },   // End position (left side)
    };

    return (
        <div className='pt-10'>
            <motion.div
                initial="initial"
                animate="animate"
                variants={motionVariant2}
                transition={{ duration: 0.9 }}
            >
                <div className='lg:float-right'>
                    <div className='lg:ml-5 mb-5'><img src={Logo} className='mx-auto' alt='' title='' /></div>
                </div>
            </motion.div>
            <motion.div
                initial="initial"
                animate="animate"
                variants={motionVariants}
                transition={{ duration: 0.3 }}
            >
                <div className='pb-5'><CompanyName />, a distinguished Egyptian joint-stock company, is proud to present a novel vision and an exclusive lifestyle to the Egyptian market, ushering in a captivating movement that embraces the allure of nature.</div>
            </motion.div>
            <motion.div
                initial="initial"
                animate="animate"
                variants={motionVariants}
                transition={{ duration: 0.5 }}
            >
                <div className='pb-5'>Our decision to align with the Finnish industry stems from Finland's esteemed reputation as one of the most advanced countries with a robust industrial foundation. Renowned for producing premium goods characterized by precision, exceptional quality, and a profound connection to nature's passion, Finland serves as the perfect source for our offerings.</div>
            </motion.div>
            <motion.div
                initial="initial"
                animate="animate"
                variants={motionVariants}
                transition={{ duration: 0.7 }}
            >
                <div className='pb-5'>At <CompanyName />, our primary goal is to bolster trade and investment between Finland and Egypt. We have forged strategic partnerships with some of Finland's most popular and respected companies, including Karelia, Polkky, and Lagerholm Finnsauna, all of which are renowned players in the timber business. Through these collaborations, we are committed to introducing the finest timber products to the Egyptian market.</div>
            </motion.div>
            <motion.div
                initial="initial"
                animate="animate"
                variants={motionVariants}
                transition={{ duration: 0.9 }}
            >
                <div className='pb-5'>Our mission is deeply rooted in our unwavering commitment to quality. We strive to contribute to the cultural advancement and progress of Egypt by consistently supplying large volumes of high-quality products under all circumstances.</div>
            </motion.div>
            <div className='pb-5'>Imagine indulging in the luxurious ambiance of your home, where every detail exudes elegance and charm. With <CompanyName />, you can acquaint yourself with the best and most evenly-crafted wood products, each carefully selected to complement your home decor and amplify your sense of dignity. The wood's natural color variations serve as a beautiful echo of nature's splendor, infusing your living spaces with a captivating aura.</div>
            <div className='pb-5'>Moreover, the versatility of wood offers boundless opportunities for design options. From classical to contemporary styles, wood adds vibrancy to any decor and serves not only as a renewable natural resource but also as a renewable interior decoration material. Designers find constant inspiration in the unique patterns of wood fibers and its ability to take on countless forms, allowing them to explore innovative ideas and bring their visions to life.</div>
            <div className='pb-5'><CompanyName /> is an integral part of EGICO Group Trading Ltd., a distinguished corporate entity established since 1988. As a part of EGICO Group, which is widely recognized as a reliable supplier of timber and processed wood products from Finland to Egypt and other Middle Eastern countries, we have grown to be a trusted and dependable force in the market. Our annual turnover amounts to an impressive 75 million EUR, a testament to our steadfast commitment to excellence and customer satisfaction.</div>
            <div>With <CompanyName />, you can rest assured that you are embarking on a journey that embraces nature's splendor, brings timeless elegance to your home, and fosters cultural enrichment through the finest quality products from Finland to Egypt. Together, we aim to create an unparalleled experience, where the harmony of nature and human ingenuity converge to redefine the way we live and adorn our spaces.</div>
            <div className='mx-auto mt-10'>
                <div className='text-center mx-auto'>
                    <div className='lg:w-1/4 mx-auto'>
                        <ThemeProvider theme={theme}><Button variant='contained' color='primary' size="large" fullWidth><LocalPhoneIcon size='large' color='secondary' />&nbsp; Call</Button></ThemeProvider>
                    </div>

                </div>
            </div>

        </div >
    )
}
