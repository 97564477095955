import React from 'react'

function Developed() {

    return (
        <span>
            Web Design & Development by <a href="https://webdesignexpressions.net/" target="_blank" rel="noreferrer" >Web Design Expressions, Inc</a>
        </span>
    )
}

export default Developed