import React from 'react';


export const CompanyName = () => {
    const CompanyName = 'FINEGYPT'
    return (

        <span aria-label="" title='FinEgypt'>{CompanyName}</span>

    )
}
