import React, { useState } from "react";
import { motion } from "framer-motion";
import { Link } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import { CompanyName } from "./CompanyName";

const Navigation = () => {
    const navItems = [
        { text: "Home", link: "/home" },
        { text: "About", link: "/about" },
        { text: "Partners", link: "/partners" },
        { text: "Contact", link: "/contact" },
    ];
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen((prev) => !prev);
    };

    return (
        <nav className="bg-[#484538] text-[#BFBFBF] md:text-center md:py-4 flex justify-between items-center">
            {/* Logo */}
            <div className="pl-4">
                <div className="text-2xl font-bold text-white">
                    <Link href="/" style={{ color: '#ffffff', textDecoration: 'none' }}><CompanyName /></Link>
                </div>
            </div>

            {/* Navigation on Large Screens */}
            <ul className="hidden md:flex justify-around text-lg md:text-xl py-1">
                {navItems.map((item, index) => (
                    <motion.li
                        key={index}
                        whileHover={{ scale: 1.05 }}
                        className="cursor-pointer px-4 py-1 transition-transform duration-200"
                    >
                        <Link href={item.link} underline="none" color="inherit">
                            {item.text}
                        </Link>
                    </motion.li>
                ))}
            </ul>

            {/* Icon Button for Mobile */}
            <div className="pr-4 md:hidden flex flex-col items-end">
                <IconButton
                    color="inherit"
                    aria-label="menu"
                    onClick={toggleMenu}
                    style={{ zIndex: 1 }}
                >
                    <MenuIcon />
                </IconButton>
                {isMenuOpen && (
                    <div className="text-center w-full">
                        {navItems.map((item, index) => (
                            <motion.div
                                key={index}
                                whileHover={{ scale: 1.05 }}
                                className="cursor-pointer px-1 py-2 transition-transform duration-200"
                                onClick={toggleMenu}
                            >
                                <Link href={item.link} underline="none" color="inherit">
                                    {item.text}
                                </Link>
                            </motion.div>
                        ))}
                    </div>
                )}
            </div>
        </nav>
    );
};

export default Navigation;