import React from 'react'
import KareliaLogo from './Images/Karelia/Karelia-main.png';
import PolkkyLogo from './Images/Polkky/Polkky-main.png'
import { Button, Link } from '@mui/material'
import { Divider } from '@mui/material'
import { createTheme, ThemeProvider } from "@mui/material/styles";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { motion } from 'framer-motion';
import Lagerholm from './Images/Lagerholm/Lagerholm-Finnsauna-main.png';

const theme = createTheme({
    palette: {
        primary: {
            main: "#3D6574", // custom primary color
        },
        secondary: {
            main: "#EBE2D3", // custom secondary color
        },
    },
});

export default function Partners() {

    const motionVariants = {
        initial: { x: "100%" }, // Start position (right side)
        animate: { x: "0%" },   // End position (left side)
    };

    return (
        <div className='pt-10'>
            <div className='mb-5'>
                <div className='lg:flex lg:gap-5'>

                    <motion.div
                        initial="initial"
                        animate="animate"
                        variants={motionVariants}
                        transition={{ duration: 0.3 }}
                    >

                        <div className='lg:float-right lg:ml-10'>

                            <div className='mb-10'><img src={KareliaLogo} className='hover:mix-blend-multiply  mx-auto' alt='' title='' /></div>
                            <div>
                                <ThemeProvider theme={theme}><Button variant='contained' color='primary' size="large" fullWidth><Link style={{ color: '#BFBFBF' }} href='/Karelia'>Explore the World of Karelia &nbsp; <NavigateNextIcon size='large' color='secondary' /></Link></Button></ThemeProvider>
                            </div>
                        </div>

                        <div>
                            <h2 className='text-2xl font-bold text-[#EBE2D3] pb-5'>Karelia: Elevating Spaces with Timeless Flooring</h2>
                        </div>
                        <div>
                            As a distinguished member of the Karelia-Upofloor Oy Group, Karelia stands at the forefront of Europe's flooring industry, renowned for its exceptional craftsmanship and innovative design. With a legacy of excellence, Karelia's net sales soared to EUR 117.9 million, driven by a dedicated team of 950 skilled individuals. Operating across Finland and with production units in Russia and Romania, Karelia's global reach extends to Western Europe, Russia, and North America. As a symbol of artistry and sustainability, Karelia's flooring masterpieces continue to inspire and elevate spaces, leaving a lasting mark on the world of design and architecture.
                        </div>

                    </motion.div>

                </div>
                <div className='my-2'><Divider /></div>
            </div>
            <div className='mb-5'>
                <div className='lg:flex lg:gap-5'>

                    <motion.div
                        initial="initial"
                        animate="animate"
                        variants={motionVariants}
                        transition={{ duration: 0.5 }}
                    >
                        <div>
                            <h2 className='text-2xl font-bold text-[#EBE2D3] pb-5'>
                                Pölkky Oy: Leading the Way in Wood Processing in Northern Finland</h2>
                        </div>
                        <div className='lg:float-right lg:ml-10'>
                            <div className=''><img src={PolkkyLogo} className='mix-blend-multiply mx-auto' alt='' title='' /></div>
                            {/* <div>
                                <ThemeProvider theme={theme}><Button variant='contained' color='primary' size="large" fullWidth><Link style={{ color: '#BFBFBF' }} href='/Karelia'>Explore the World of Polkky &nbsp; <NavigateNextIcon size='large' color='secondary' /></Link></Button></ThemeProvider>
                            </div> */}
                        </div>

                        <div className='lg:pr-10'>
                            Pölkky Oy is the largest private wood processing company in Northern Finland. Their sawmills and processing facilities are situated in Kuusamo and Taivalkoski, at the core of Finland's finest raw timber region. The company utilizes modern production technology while maintaining a strong commitment to tradition. With modern and efficient facilities, they ensure the high quality of their wood products, both now and in the future. With forty years of experience in wood processing, Pölkky Oy stands as a reliable industry leader.
                        </div>

                    </motion.div>

                </div>
                <div className='my-2'><Divider /></div>
            </div>
            <div className='mb-5'>
                <div className='lg:flex lg:gap-5'>

                    <div className=''>

                        <div className='lg:float-right lg:ml-10'>
                            <div><img src={Lagerholm} className='mix-blend-multiply mx-auto' alt='' title='' /></div>
                            {/* <div>
                                <ThemeProvider theme={theme}><Button variant='contained' color='primary' size="large" fullWidth><Link style={{ color: '#BFBFBF' }} href='/Karelia'>Explore the World of Lagerholm &nbsp; <NavigateNextIcon size='large' color='secondary' /></Link></Button></ThemeProvider>
                            </div> */}
                        </div>
                        <div>
                            <h2 className='text-2xl font-bold text-[#EBE2D3] pb-5'>Discover Relaxation and Quality with Lagerholm since 1968</h2>
                        </div>
                        <div>
                            With a rich heritage spanning over 40 years, Lagerholm stands as a symbol of unparalleled quality and dedicated service. Since our inception in 1968, our mission has been to create a sanctuary of relaxation, offering a diverse range of enriching experiences.<br /><br />
                            At Lagerholm, you can immerse yourself in the art of tranquility through our exquisite saunas, infrared cabins, and steam baths, providing moments of rejuvenation and serenity. Escape to our charming garden cabins, where nature's beauty envelops you in peaceful solitude. With a commitment to enduring craftsmanship, we ensure access to top-quality raw materials, ensuring our products stand the test of time. Moreover, our dedication to environmentally-conscious product development sets us on a path towards a sustainable future.
                        </div>


                    </div>

                </div>

            </div>
        </div>
    )
}
