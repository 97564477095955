import React from 'react'
import { CompanyName } from './Component/CompanyName';
import KareliaOne from './Images/Karelia/1.jpg';
import KareliaTwo from './Images/Karelia/2.jpg';
import KareliaThree from './Images/Karelia/3.jpg';
import KareliaLogo from './Images/Karelia/Karelia-main.png'
import PolkkyLogo from './Images/Polkky/Polkky-main.png'
import PolkkyOne from './Images/Polkky/1.jpg'
import Polkkytwo from './Images/Polkky/2.jpg'
import Polkkythree from './Images/Polkky/3.jpg'
import LagerholmOne from './Images/Lagerholm/1.jpg'
import Lagerholmtwo from './Images/Lagerholm/2.jpg'
import Lagerholmthree from './Images/Lagerholm/3.jpg'
import Lagerholm from './Images/Lagerholm/Lagerholm-Finnsauna-main.png';
import Slider from './Component/Slider';
import { motion } from "framer-motion";
import { Divider } from '@mui/material';



export default function Home() {

    const motionVariants = {
        initial: { x: "100%" }, // Start position (right side)
        animate: { x: "0%" },   // End position (left side)
    };



    return (
        <div>
            <div>
                <motion.div
                    initial="initial"
                    animate="animate"
                    variants={motionVariants}
                    transition={{ duration: 0.1 }}
                >
                    <div className='pt-10'><span className='text-[#EBE2D3] text-2xl'>Welcome to <CompanyName /></span></div>
                </motion.div>
                <motion.div
                    initial="initial"
                    animate="animate"
                    variants={motionVariants}
                    transition={{ duration: 0.3 }}
                >
                    <div className='pb-5'> Your Gateway to Nature-Inspired Hardwood Floors! Discover a New Vision and Embrace Unique Lifestyles</div>
                </motion.div>
                <div className='lg:float-right pb-5'><Slider /></div>
                <motion.div
                    initial="initial"
                    animate="animate"
                    variants={motionVariants}
                    transition={{ duration: 0.6 }}
                >
                    <div className='pb-5'>At <CompanyName />, we believe in reconnecting with the timeless beauty of nature, and we are thrilled to bring this movement to the Egyptian market. Our passion for nature's wonders drives us to introduce you to the finest hardwood floors that exude warmth, charm, and unmatched quality.</div>
                </motion.div>
                <motion.div
                    initial="initial"
                    animate="animate"
                    variants={motionVariants}
                    transition={{ duration: 0.9 }}
                >
                    <div className='pb-5'>An Exclusive Partnership with Finland's Finest</div>
                </motion.div>
                <motion.div
                    initial="initial"
                    animate="animate"
                    variants={motionVariants}
                    transition={{ duration: 1.2 }}
                >
                    <div className='pb-5'><CompanyName /> takes immense pride in being the exclusive distributor of some of Finland's most renowned companies, known for their commitment to excellence and nature-inspired craftsmanship. Our partnership allows us to bring you premium products that epitomize sophistication and harmony with the environment.</div>
                </motion.div>

                <div className='text-[#EBE2D3] text-3xl text-center'>Explore Our Premier Brands</div>

                <div className='my-5 bg-[#BFBFBF]'>
                    <div className='p-1'>
                        <div className='bg-[#999999]'>
                            <div className='p-5'>
                                <div className='lg:flex'>

                                    <div className='lg:w-1/4'>
                                        <div><img src={KareliaLogo} className='hover:mix-blend-multiply mx-auto' alt='' title='' /></div>
                                    </div>
                                    <div className='mx-5'><Divider orientation='vertical' /></div>
                                    <div className='lg:w-3/4 text-[#EBE2D3]'>

                                        <div className='pb-5'>Elevate Your Space with Exquisite Hardwood Floors</div>
                                        <div>Our partnership with Karelia, a pioneer in the world of hardwood floors, offers you a vast array of design choices to transform your living spaces. Whether you seek classic elegance or contemporary flair, Karelia's hardwood floors stand the test of time, offering enduring beauty for generations to come.</div>

                                    </div>



                                </div>


                            </div>
                        </div>
                    </div>
                </div>

                <div className='lg:flex lg:gap-5'>
                    <div className='lg:w-1/3 my-2 p-1 text-center bg-[#BFBFBF] '>
                        <div><img src={KareliaOne} className='hover:mix-blend-multiply mx-auto' alt='' title='' /></div>

                    </div>
                    <div className='lg:w-1/3 my-2 p-1 text-center bg-[#BFBFBF]'>
                        <div><img src={KareliaTwo} className='hover:mix-blend-multiply mx-auto' alt='' title='' /></div>

                    </div>
                    <div className='lg:w-1/3 my-2 p-1 text-center bg-[#BFBFBF]'>
                        <div><img src={KareliaThree} className='hover:mix-blend-multiply mx-auto' alt='' title='' /></div>

                    </div>
                </div>

                <div className='my-5 bg-[#BFBFBF]'>
                    <div className='p-1'>
                        <div className='bg-[#999999]'>
                            <div className='p-5'>
                                <div className='lg:flex'>

                                    <div className='lg:w-1/4'>
                                        <div><img src={PolkkyLogo} className='mix-blend-multiply mx-auto' alt='' title='' /></div>
                                    </div>
                                    <div className='mx-5'><Divider orientation='vertical' /></div>
                                    <div className='lg:w-3/4 text-[#EBE2D3]'>

                                        <div className='pb-5'>Unleash Creativity with Impregnated Wood, Wall Panels & Flooring Boards</div>
                                        <div>Polkky's innovative approach to impregnated wood, wall panels, and flooring boards empowers you to unleash your creativity in interior design. Embrace the unique patterns and textures that Polkky brings to the table, allowing you to craft spaces that resonate with your personal style.</div>

                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className='lg:flex lg:gap-5'>
                    <div className='lg:w-1/3 my-2 p-1 text-center bg-[#BFBFBF]'>
                        <div><img src={PolkkyOne} className='hover:mix-blend-multiply mx-auto' alt='' title='' /></div>

                    </div>
                    <div className='lg:w-1/3 my-2 p-1 text-center bg-[#BFBFBF]'>
                        <div><img src={Polkkytwo} className='hover:mix-blend-multiply mx-auto' alt='' title='' /></div>

                    </div>
                    <div className='lg:w-1/3 my-2 p-1 text-center bg-[#BFBFBF]'>
                        <div><img src={Polkkythree} className='hover:mix-blend-multiply mx-auto' alt='' title='' /></div>

                    </div>
                </div>

                <div className='my-5 bg-[#BFBFBF]'>
                    <div className='p-1'>
                        <div className='bg-[#999999]'>
                            <div className='p-5'>
                                <div className='lg:flex'>

                                    <div className='lg:w-1/4'>
                                        <div><img src={Lagerholm} className='mx-auto' alt='' title='' /></div>
                                    </div>
                                    <div className='mx-5'><Divider orientation='vertical' /></div>
                                    <div className='lg:w-3/4 text-[#EBE2D3]'>

                                        <div className='pb-5'>Experience the Tradition of Finnish Saunas & Sauna Heaters</div>
                                        <div>Indulge in the ancient tradition of Finnish saunas with Lagerholm Finnsauna. We present you with a range of authentic saunas and sauna heaters, meticulously crafted to recreate the tranquil ambiance of traditional Finnish saunas. Embrace relaxation and rejuvenation like never before.</div>

                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className='lg:flex lg:gap-5'>
                    <div className='lg:w-1/3 my-2 p-1 text-center bg-[#BFBFBF]'>
                        <div><img src={Lagerholmtwo} className='hover:mix-blend-multiply mx-auto' alt='' title='' /></div>

                    </div>
                    <div className='lg:w-1/3 my-2 p-1 text-center bg-[#BFBFBF]'>

                        <div><img src={LagerholmOne} className='hover:mix-blend-multiply mx-auto' alt='' title='' /></div>
                    </div>
                    <div className='lg:w-1/3 my-2 p-1 text-center bg-[#BFBFBF]'>
                        <div><img src={Lagerholmthree} className='hover:mix-blend-multiply mx-auto' alt='' title='' /></div>

                    </div>
                </div>



                <div className='text-[#EBE2D3] text-2xl pb-5 pt-10'>Why Choose <CompanyName />?</div>

                <div className='lg:flex lg:gap-5'>
                    <div className='lg:w-1/5 text-center'>
                        <div className='pb-5 text-[#EBE2D3]'>Exceptional Quality</div>
                        <div>Our products are sourced from Finland's finest, renowned for their exceptional quality and environmental responsibility.</div>
                    </div>
                    <div className='mx-5'><Divider orientation='vertical' /></div>
                    <div className='lg:w-1/5 text-center'>
                        <div className='pb-5 text-[#EBE2D3]'>Professional Guidance</div>
                        <div>Our experts are ready to provide personalized guidance, helping you make the best decisions for your space.</div>
                    </div>
                    <div className='mx-5'><Divider orientation='vertical' /></div>
                    <div className='lg:w-1/5 text-center'>
                        <div className='pb-5 text-[#EBE2D3]'>Endless Inspiration</div>
                        <div>Explore a plethora of design options and let nature inspire your creativity.</div>
                    </div>
                    <div className='mx-5'><Divider orientation='vertical' /></div>
                    <div className='lg:w-1/5 text-center'>
                        <div className='pb-5 text-[#EBE2D3]'>Lasting Value</div>
                        <div>Invest in hardwood floors that enhance your property's value and charm for years to come.</div>
                    </div>
                    <div className='mx-5'><Divider orientation='vertical' /></div>
                    <div className='lg:w-1/5 text-center'>
                        <div className='pb-5 text-[#EBE2D3]'>Green Living</div>
                        <div>Embrace eco-friendly choices with sustainable hardwood and environmentally-conscious practices.</div>
                    </div>
                </div>

                <div className='mt-10'><Divider /></div>

                <div className='py-5'>We cordially invite you to experience the world of <CompanyName /> firsthand. Our showroom is a testament to the exquisite products we offer, showcasing the splendor of natural hardwood floors, impregnated wood, wall panels, and saunas.</div>
                <div className='pb-5'>Discover Nature's Charm in Every Step</div>
                <div className='pb-5'>With <CompanyName />, your home becomes a canvas for nature's charm, where every step is a celebration of timeless beauty. Our dedicated team is eager to assist you in selecting the perfect flooring solution, tailored to your unique vision.</div>


                <div className='py-5'>At <CompanyName />, we envision a world where every home is elevated by nature's beauty. Step into our showroom and let us guide you through a journey of inspiration, craftsmanship, and the allure of hardwood floors.</div>
                <div>Contact us today to schedule a visit or explore our website to get a glimpse of the wonders that await you at <CompanyName />. Together, let's embrace the harmony of nature and create spaces that reflect your distinctive lifestyle.</div>


            </div>

        </div >
    )
}
