import './App.css';
import Footer from './Component/Footer';
import { CompanyName } from './Component/CompanyName';
import { Link, BrowserRouter as Router, Route } from "react-router-dom";
import Home from './Home';
import { Switch } from "react-router-dom";
import Navigation from './Component/Navigation';
import Contact from './Contact';
import About from './About';
import Partners from './Partners';
import Karelia from './Karelia';
import { motion } from 'framer-motion';

//bg: Dusty Gray #999999
//text color White Rock: #EBE2D3

//Buttons: #BFBFBF



function App() {
  return (
    <div className='bg-primary custom-font'>
      <Navigation />
      <div className='px-10'>
        <Router>
          <Switch>

            <Route exact path="/" component={Home}></Route>
            <Route exact path="/home" component={Home}></Route>
            <Route exact path="/About" component={About}></Route>
            <Route exact path="/Partners" component={Partners}></Route>
            <Route exact path="/Karelia" component={Karelia}></Route>
            <Route exact path="/Contact" component={Contact}></Route>

          </Switch>

          <Footer />

        </Router>
      </div>
    </div >
  );
}


export default App;
