import React from 'react'
import FinEgyptLogo from './Images/FinEgypt-Logo.png'
import { Divider } from '@mui/material'

export default function Contact() {
    return (
        <div className='pt-10'>


            <div className='lg:flex lg:gap-5'>
                <div className='lg:w-1/2'>
                    <div className='my-5'><img src={FinEgyptLogo} alt='' title='' /></div>
                    <div><Divider /></div>
                    <div className='pt-10'>
                        <address>
                            <div>190 Nile St., El-Agouza, Giza, Egypt.</div>
                            <div>Tel: +(202) 330 44 695</div>
                            <div>Fax.: +(202) 330 44 683</div>
                            <div>E-mail.:	mohamed.habashy@egyfin.com</div>
                            <div>Web Site.:	www.egyfin.com</div>

                        </address>

                    </div>
                </div>
                <div className='lg:w-1/2'>
                    <div>
                        <iframe className="shadow-2xl border-4 border-[#BFBFBF] mx-auto w-full aspect-video align-middle" title='FinEgypt' src="https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d6906.003173606032!2d31.213695000000005!3d30.065489!3m2!1i1024!2i768!4f13.1!3m2!1m1!2s!5e0!3m2!1sen!2sus!4v1690050139639!5m2!1sen!2sus" width="400" height="400" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
                        </iframe>
                    </div>
                </div>
            </div>

        </div>
    )
}
