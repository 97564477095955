import React from 'react'
import KareliaLogo from './Images/Karelia/Karelia-main.png';
import KareliaFour from './Images/Karelia/4.png';
import KareliaFive from './Images/Karelia/5.png';
import Kareliasix from './Images/Karelia/6.png'

export default function Karelia() {
    return (
        <div className='pt-10'>
            <div>
                <div className='text-2xl font-bold text-[#EBE2D3] pb-5'><h1>Karelia-Upofloor Oy Group: Pioneering Flooring Excellence</h1></div>
                <div className='lg:flex lg:gap-5'>
                    <div>
                        <div className='lg:float-right'>
                            <img src={KareliaLogo} className='hover:mix-blend-multiply p-1 mx-auto' alt='' title='' />
                        </div>
                        <div className='pb-5'>Karelia is a distinguished member of the esteemed Karelia-Upofloor Oy Group, a renowned conglomerate and one of Europe's leading flooring manufacturers. With an impressive legacy spanning decades, Karelia has earned its position as a trailblazer in the industry, consistently delivering exceptional flooring solutions that blend unparalleled craftsmanship with innovative design.</div>
                        <div className='lg:float-right lg:ml-5 py-5'><img src={KareliaFour} className='hover:mix-blend-multiply mx-auto' alt='' title='' /></div>

                        <div className='pb-5'>Driven by a commitment to excellence, Karelia's net sales have soared to extraordinary heights, reaching an impressive EUR 117.9 million in the most recent fiscal year. This remarkable success is a testament to the dedicated team of over 950 talented individuals who pour their heart and soul into creating flooring masterpieces that surpass expectations.</div>
                        <div className='lg:float-right lg:ml-5 py-5'><img src={KareliaFive} className='hover:mix-blend-multiply mx-auto' alt='' title='' /></div>

                        <div className='pb-5'>The Group's influence extends across regions, with four state-of-the-art production units strategically located in different parts of Finland. Additionally, Karelia has established hardwood floor factories in Russia and Romania, leveraging international capabilities to meet the growing demand for its exceptional products.</div>
                        <div className='lg:float-right lg:ml-5 py-5'><img src={Kareliasix} className='hover:mix-blend-multiply mx-auto' alt='' title='' /></div>

                        <div className='pb-5'>To extend its global reach, Karelia operates a dedicated sales office in Estonia, establishing valuable connections and strong relationships with clients and partners in the Baltic region, enhancing its comprehensive market presence.</div>
                        <div className='lg:float-right lg:ml-5 py-5'><img src={KareliaFour} className='hover:mix-blend-multiply mx-auto' alt='' title='' /></div>

                        <div className='pb-5'>Despite its roots in Finland, Karelia's captivating creations have resonated with customers worldwide. It has made significant strides in captivating the hearts of discerning clientele across Western Europe, Russia, and North America, tailoring its offerings to suit the unique tastes and preferences of each market.</div>
                        <div className='pb-5'>Karelia's vision is to transform living spaces into breathtaking works of art, blending nature's finest materials with cutting-edge technology. Each Karelia flooring masterpiece encapsulates the essence of timeless elegance and sophistication.</div>

                        <div className='pb-5'>Sustainability and environmental responsibility are at the core of Karelia's operations, ensuring a positive impact on the environment and the communities it serves.</div>

                        <div className='pb-5'>As an emblem of ingenuity and integrity, Karelia heralds a new era of flooring excellence for generations to come, ready to inspire and elevate spaces into realms of transcendent beauty and functionality, embodying the Group's core philosophy of "floors for life."</div>

                        <div className='text-2xl font-bold text-[#EBE2D3] pb-5'><h2>The Hardwood Roots of Karelia</h2></div>
                        <div className='pb-5'>The origins of Karelia hardwood floors can be traced back to the 1530s when the Väänänen family settled in Eastern Finland, passing on a legacy of respect for the land and forests, entrepreneurial spirit, and unwavering integrity. Heikki Väänänen, the founder of Karelia, continues to uphold these values, driving the company's growth from a family business in Savo to an international trailblazer.</div>
                        <div className='text-2xl font-bold text-[#EBE2D3] pb-5'><h2>Karelia Saima Classic: Architectural Splendor in Design</h2></div>

                        <div className='pb-5'>Karelia Saima Classic brings top Finnish design to homes, offering various composition options to create a new sense of space and splendor. From Herringbone to classic Dutch design patterns, Karelia Saima Classic, made of birch, emanates a lovely sheen with the play of light.</div>
                        <div className='text-2xl font-bold text-[#EBE2D3] pb-5'><h2>Wide Range of Wood Species</h2></div>
                        <div className='pb-5'>Karelia offers a wide variety of unique and interesting wood species for its hardwood floorings, allowing customers to choose between fresh, light shades and elegant, dark colors. Cherry, with its subdued fiber patterns, is favored by individualistic decorators, as it acquires a darker shade of red over time.</div>
                        <div className='text-2xl font-bold text-[#EBE2D3] pb-5'><h2>Find Your Style with Karelia</h2></div>
                        <div>Karelia's stained and heat-treated special collections, such as Rustikal, Robust, or Polar, allow customers to create vibrant and individualistic decor solutions. The classic three-strip board adds vibrancy to any decor, and a lively-colored wood species like Jarrah creates a vivid surface. For those seeking a luxurious ambiance, the Karelia Story and Saima collections offer exquisite options, manufactured from the best, most even-looking part of the wood.</div>



                    </div>


                </div>
            </div>

            <div className='mt-10'>
                <iframe
                    className="border-4 border-[#BFBFBF] mx-auto w-full aspect-video align-middle"

                    src="https://www.youtube.com/embed/kytSaUMSlR0"
                    title="YouTube video player"
                    frameborder="2"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                ></iframe>
            </div>
        </div>
    )
}
