import { Divider } from '@mui/material'
import React from 'react'
import Link from '@mui/material/Link';
import { CompanyName } from './CompanyName';
import Developed from './Developed';

const today = new Date();

export default function Footer() {
    return (
        <div>

            <div className='py-5 text-[#EBE2D3]'>
                <div className='py-5'><Divider /></div>
                <div className='pt-3'><Link href='/Home' color={'inherit'} className='' exact="true">&copy; {today.getFullYear()} - <CompanyName /></Link> All rights reserved</div>
                <div className='py-1'><Developed /></div>
            </div>
        </div>
    )
}
