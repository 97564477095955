import React, { useEffect, useState } from 'react';
import { motion } from "framer-motion";
import Image1 from '../Images/Karelia/Karelia-01.jpg';
import Image2 from '../Images/Karelia/Karelia-02.jpg';
import Image3 from '../Images/Karelia/Karelia-03.jpg';
import Image4 from '../Images/Karelia/Karelia-04.jpg';
import Image5 from '../Images/Karelia/Karelia-05.jpg';


const images = [Image1, Image2, Image3, Image4, Image5];

const Slider = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 2000); // Set the desired delay here (in milliseconds)

        return () => clearInterval(interval);
    }, [images.length]);

    const handleReplay = () => {
        setCurrentIndex(0);
    };

    return (
        <div className="flex overflow-hidden">
            {images.map((image, index) => (
                <motion.img
                    key={index}
                    src={image}
                    alt={`Image ${index + 1}`}
                    className={`shadow-lg w-full mx-auto `}
                    // style={{ position: 'absolute', left: `${index * 20}%` }}
                    initial={{ opacity: index === 0 ? 1 : 0 }}
                    animate={{ opacity: 1 }}

                    transition={{ duration: 0.5, delay: index * 0.5 }}

                />
            ))}

        </div>

    );
};

export default Slider;